import type { AdCookieNameType } from '@src/domains/foundation/tracking/cookies/cookie-types';
import {
  type CookieOptions,
  getCookie as _getCookie,
  getCookieFromCookieString as _getCookieFromCookieString,
  getCookies as _getCookies,
  serializeCookie as _serializeCookie,
  serializeUnsetCookie as _serializeUnsetCookie,
  setCookie as _setCookie,
  unsetCookie as _unsetCookie,
} from './utils';
export { getAmplitudeSessionIdFromCookieString } from './amplitude';
export {
  getUser,
  getUserFromCookieString,
  serializeUnsetUser,
  setSignUpCompleted,
  setUser,
  unsetUser,
} from './user';
export { getUserToken } from './user-token';

// Excludes private cookie names such as user and userToken
export const CookieName = {
  User: 'user',
  ReferringDomain: 'referringDomain',
  AfterAuthRedirect: 'afterAuthRedirect',
  SignupSource: 'signupSource',
  SignupSourceQualifier: 'signupSourceQualifier',
  Mobile: 'trusted_rn_app_info',
  userRoleType: 'userRoleType',
  // Set by 3rd party script
  AjsAnonymousId: 'ajs_anonymous_id',
  CsrfToken: 'csrfToken',
} as const;

export type CookieNameType =
  | (typeof CookieName)[keyof typeof CookieName]
  | AdCookieNameType;

// Wrapping private methods to ensure only expected cookie names (CookieName enum) are allowed
export const getCookie = (cookieName: CookieNameType) => _getCookie(cookieName);

export const getCookieFromCookieString = (
  cookieName: CookieNameType,
  cookieString: string
) => _getCookieFromCookieString(cookieName, cookieString);

export const getCookies = (...cookieNames: CookieNameType[]) =>
  _getCookies(...cookieNames);

export const serializeCookie = (cookieName: CookieNameType, value: string) =>
  _serializeCookie(cookieName, value);

export const serializeUnsetCookie = (cookieName: CookieNameType) =>
  _serializeUnsetCookie(cookieName);

export const setCookie = (
  cookieName: CookieNameType,
  value: string,
  cookieOptions?: CookieOptions
) => _setCookie(cookieName, value, cookieOptions);

export const unsetCookie = (cookieName: CookieNameType) =>
  _unsetCookie(cookieName);
